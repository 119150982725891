import {gql} from 'apollo-angular';
import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../models/user/user';
import { Me } from '../../models/user/me';
import { ClientFragment } from 'src/app/models/client/client-fragment';

import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss'],
})
export class UserPhotoComponent implements OnInit {
  static readonly USER_FRAG = new ClientFragment({
    type: User.TYPENAME,
    name: 'UserPhotoComponentUser',
    gqlEntry: gql`
      fragment UserPhotoComponentUser on User {
        photoUrl
      }
    `,
  });
  faUserCircle = faUserCircle;

  @Input()
  user: User | Me | null;

  constructor() {}

  ngOnInit() {}
}
