import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent } from './login-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UserPhotoModule } from '../user-photo/user-photo.module';
import { FirebaseUIModule } from 'firebaseui-angular';
import { firebaseUiAuthConfig } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { IconSpinnerModule } from 'src/app/icon-common/icon-spinner/icon-spinner.module';

@NgModule({
    declarations: [LoginDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        UserPhotoModule,
        IconSpinnerModule,
        FirebaseUIModule.forRoot(firebaseUiAuthConfig),
        RouterModule,
    ],
    exports: [LoginDialogComponent]
})
export class LoginDialogModule {}
