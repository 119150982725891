import { DocumentNode } from 'graphql';

export class ClientFragment {
  name: string;
  type: string;
  gqlEntry?: DocumentNode;

  constructor(init?: Partial<ClientFragment>) {
    Object.assign(this, init);
  }

  toString() {
    if (this.gqlEntry && this.gqlEntry.loc) {
      return this.gqlEntry.loc.source.body;
    } else {
      throw new Error(
        'Cannot find gqlEntry.loc of ClientFragment' + this.name + '.'
      );
    }
  }
}
