import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MeService } from '../models/user/me.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad {
  constructor(private meService: MeService) {}
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.meService.prepare().pipe(map((me) => me.isAdmin));
  }
}
