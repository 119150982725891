import { LensExterior } from 'src/app/models/lens-basic/lens-exterior';
import { LensWeight } from 'src/app/models/lens-basic/lens-weight';
import { FujiLensThumbs } from '../fuji-lens/fuji-lens-thumbs';
import { LensNames } from '../lens-basic/lens-names';
import { Pricing } from '../pricing/pricing';
import { Product } from '../product/product';
import { Resolvable } from '../resolvable/resolvable';

export class FujiTeleconverter implements Product, Resolvable {
  static readonly TYPENAME = 'FujiTeleconverter';
  // tslint:disable-next-line: variable-name
  __typename: string = FujiTeleconverter.TYPENAME;
  id!: string;
  apertureDecrease!: number; // stop
  exterior?: LensExterior;
  magnificationMultiplication!: number;
  names!: LensNames;
  productName!: string;
  standalone!: boolean;
  thumbs?: FujiLensThumbs;
  weight?: LensWeight; // g
  urn!: string;

  pricing: Pricing;
}
