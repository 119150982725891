import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  MessageConfig,
  MessageService
} from 'src/app/models/message/message.service';
import { Me } from '../../models/user/me';
import { MeService } from '../../models/user/me.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  me: Me = Me.empty();
  meSubs: Subscription;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private meService: MeService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.meSubs = this.meService.watchMe().subscribe(me => {
      this.me = me;
    });
  }

  ngOnDestroy() {
    this.meSubs.unsubscribe();
  }

  logout() {
    this.meService.logout().subscribe(
      _ => {},
      err =>
        this.messageService.handleError(
          err,
          new MessageConfig({ level: 'Error', scope: 'Dialog' }),
          () => this.logout()
        )
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
