import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogClickableComponent } from './login-dialog-clickable.component';
import { LoginDialogModule } from '../login-dialog/login-dialog.module';

@NgModule({
  declarations: [LoginDialogClickableComponent],
  imports: [CommonModule, LoginDialogModule],
  exports: [LoginDialogClickableComponent]
})
export class LoginDialogClickableModule {}
