<div class="page-width-limit container">
  <div class="main-panel">
    <div class="copyright">&copy; 2020 stw.photo</div>
    <div class="footer-menu">
      <a routerLink="/about/privacy">Privacy Policy</a>
      <a routerLink="/about/terms">Terms</a>
      <a routerLink="/about">About</a>
      <a routerLink="/about/contact">Contact</a>
    </div>
  </div>
</div>
