import {Apollo, gql} from 'apollo-angular';
import { Injectable } from '@angular/core';


import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FujiLensService } from 'src/app/models/fuji-lens/fuji-lens.service';
import { UrnObj } from 'src/app/models/urn/urn-obj';
import { ClientFragment } from '../client/client-fragment';
import { ClientScenario } from '../client/client-scenario';
import { FujiLens } from '../fuji-lens/fuji-lens';
import { Pager } from '../pager/pager';
import { Paging } from '../pager/paging';
import { MaybePage, Page } from './page';

const LENS_SCENARIO = new ClientScenario({
  fujiLensFragment: new ClientFragment({
    type: FujiLens.TYPENAME,
    name: 'PageServiceFujiLens',
    gqlEntry: gql`
      fragment PageServiceFujiLens on FujiLens {
        id
        names {
          full
          compact
        }
        slug
      }
    `,
  }),
});

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(
    private logger: NGXLogger,
    private lensService: FujiLensService,
    private apollo: Apollo
  ) {}

  resolveOnePage(scenario: ClientScenario, pageUrn: string) {
    const pageId = UrnObj.toId(pageUrn, [Page.TYPENAME]);
    return this.getOnePage(scenario, pageId);
  }

  getOnePage(scenario: ClientScenario, pageId: string): Observable<MaybePage> {
    this.logger.debug(`Loading one page ${pageId} in PageService.`);
    return this.apollo
      .query<any>({
        query: gql`
        query page($id: ID!) {
          page(id: $id) {
            id
            ...${scenario.pageFragment.name}
          }
        }
        ${scenario.pageFragment.gqlEntry}
        `,
        variables: { id: pageId },
      })
      .pipe(map((result) => result.data.page as MaybePage));
  }

  resolvePages(
    scenario: ClientScenario,
    pageUrns: string[]
  ): Observable<Page[]> {
    const pageIds = pageUrns.map((urn) => UrnObj.toId(urn, [Page.TYPENAME]));
    return this.getSomePages(scenario, pageIds);
  }

  getSomePages(
    scenario: ClientScenario,
    pageIds: string[]
  ): Observable<MaybePage[]> {
    this.logger.debug(`Loading ${pageIds.length} pages in PageService.`);
    return this.apollo
      .query<any>({
        query: gql`
        query somePages($ids: [ID!]!) {
          somePages(ids: $ids) {
            id
            ...${scenario.pageFragment.name}
          }
        }
        ${scenario.pageFragment.gqlEntry}
      `,
        variables: { ids: pageIds },
      })
      .pipe(map((result) => result.data.somePages as Page[]));
  }

  getLatestTopics(scenario: ClientScenario, paging: Paging) {
    return this.apollo
      .query<any>({
        query: gql`
        query latestTopics($paging: Paging) {
          latestTopics(paging: $paging) {
            items {
              ...on Page {
                ...${scenario.pageFragment.name}
              }
            }
          }
        }
        ${scenario.pageFragment.gqlEntry}
      `,
        variables: { paging },
      })
      .pipe(map((result) => result.data.latestTopics as Pager<Page>));
  }

  findLensDetailPage(tuple: string[], id: string): Observable<Page> {
    this.logger.debug(`Finding lens page of ${id} in PageService.`);
    if (tuple.length !== 2) {
      throw Error('Cannot find lens urn in ID ' + id);
    }
    const lensUrn = tuple[1];
    return this.lensService.findByUrn(LENS_SCENARIO, lensUrn).pipe(
      map((lens) => {
        if (!lens) {
          throw Error(`Cannot find lens by lens urn ${lensUrn}`);
        }
        return new Page({
          id,
          title: 'Fujifilm ' + lens.names.compact,
          breadcrumbTitle: lens.names.compact,
          path: '/fuji-x/lens/' + lens.slug + '/',
        });
      })
    );
  }
}
