import { User } from './user';

export class Me {
  // tslint:disable-next-line: variable-name
  __typename = 'Me';
  id: string;
  userUrn: string;
  createdAt?: Date;
  updatedAt?: Date;
  isMember: boolean;
  isAdmin: boolean;
  credential: any;
  mergedFromId?: string;
  mergedToId?: string;
  mergedFromUrn?: string;
  displayName: string;
  photoUrl: string;
  email: string;
  emailVerified: boolean;
  providerId: string;

  constructor(init?: Partial<Me>) {
    Object.assign(this, init);
  }

  get isPrepared(): boolean {
    return this.id && this.id !== 'not-login';
  }

  get isGuest(): boolean {
    return this.isPrepared && !this.isMember;
  }

  static fromRaw(raw: any) {
    return new Me(raw);
  }

  static empty() {
    return new Me({ id: 'not-login' });
  }

  toUser() {
    return new User({
      id: this.id,
      isMember: this.isMember,
      displayName: this.displayName,
      photoUrl: this.photoUrl
    });
  }
}
