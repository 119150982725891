import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBook,
  faHome,
  faImages,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { LoginDialogClickableModule } from '../user-common/login-dialog-clickable/login-dialog-clickable.module';
import { UserPhotoModule } from '../user-common/user-photo/user-photo.module';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppSidenavComponent } from './app-sidenav/app-sidenav.component';

@NgModule({
  declarations: [AppHeaderComponent, AppFooterComponent, AppSidenavComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    MatDialogModule,
    LoginDialogClickableModule,
    UserPhotoModule,
    FontAwesomeModule,
  ],
  exports: [AppHeaderComponent, AppFooterComponent, AppSidenavComponent],
})
export class AppFrameModule {
  faHome = faHome;
  faImages = faImages;
  faBook = faBook;
  faTask = faTasks;
  constructor() {}
}
