import { Resolvable } from '../resolvable/resolvable';

export class User implements Resolvable {
  static readonly TYPENAME = 'User';
  // tslint:disable-next-line: variable-name
  __typename = User.TYPENAME;
  id: string;
  urn: string;
  isMember: boolean;
  isAdmin: boolean;
  credential: any;
  displayName: string;
  photoUrl: string;
  email: string;
  emailVerified: boolean;
  providerId: string;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}
