import {ErrorResponse} from '@apollo/client/link/error';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NGXLogger } from 'ngx-logger';
import { SpinnerOverlayComponent } from 'src/app/app-deco/spinner-overlay/spinner-overlay.component';
import { MessageView } from './message-view';

export class MessageConfig {
  level: 'Info' | 'Warn' | 'Error' | 'Fatal';
  scope:
    | 'Page'
    | 'Block'
    | 'Dialog'
    | 'Selector'
    | 'Multi'
    | 'Widget'
    | 'React'
    | 'Form';
  view: MessageView;
  fallbackMessage: string;

  constructor(init?: Partial<MessageConfig>) {
    Object.assign(this, init);
  }
}

class GlobalMessageView implements MessageView {
  loadingRequests = 0;
  overlayRef: OverlayRef;
  retryCallbacks: (() => void)[] = [];

  constructor(private snackBar: MatSnackBar, private overlay: Overlay) {}

  showLoading(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        width: '100vw',
        height: '100vh',
      });
    }
    if (this.loadingRequests === 0) {
      const portal = new ComponentPortal(SpinnerOverlayComponent);
      this.overlayRef.attach(portal);
    }
    this.loadingRequests++;
  }

  hideLoading(): void {
    if (this.loadingRequests === 1) {
      this.overlayRef.detach();
    }
    this.loadingRequests--;
  }

  showSuccess(message: string): void {
    this.snackBar.open(message, undefined, { duration: 5000 });
  }

  showFailure(message: string, retryCallback?: () => void): void {
    if (!!retryCallback) {
      this.retryCallbacks.push(retryCallback);
    }
    if (this.retryCallbacks.length > 0) {
      const retryAction =
        this.retryCallbacks.length === 1
          ? 'Retry'
          : 'Retry (' + this.retryCallbacks.length + ')';
      const snackBarRef = this.snackBar.open(message, retryAction, {
        duration: 0,
      });
      snackBarRef.onAction().subscribe(() => {
        const callbacks = this.retryCallbacks;
        this.retryCallbacks = [];
        callbacks.forEach((callback) => callback());
      });
    } else {
      this.snackBar.open(message, null, { duration: 8000 });
    }
  }

  hideMessage(): void {
    this.snackBar.dismiss();
  }
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private globalMessageView: MessageView;
  constructor(
    public snackBar: MatSnackBar,
    private logger: NGXLogger,
    overlay: Overlay
  ) {
    this.globalMessageView = new GlobalMessageView(snackBar, overlay);
  }

  showLoading() {
    this.globalMessageView.showLoading();
  }

  hideLoading() {
    this.globalMessageView.hideLoading();
  }

  handleError(error: any, config: MessageConfig, retryCallback?: () => void) {
    this.logger.error('Got error in MessageService.', error);
    if (typeof error === 'string') {
      this.handleStringError(error as string, config);
    } else if (
      typeof error === 'object' &&
      ('graphQLErrors' in error || 'networkError' in error)
    ) {
      this.handleApolloError(error as ErrorResponse, config, retryCallback);
    } else {
      this.handleUnknownError(error, config, retryCallback);
    }
  }

  private handleStringError(
    error: string,
    config: MessageConfig,
    retryCallback?: () => void
  ) {
    const view = this.resolveView(config);
    this.displayError(error, view, retryCallback);
  }

  private handleApolloError(
    errRes: ErrorResponse,
    config: MessageConfig,
    retryCallback?: () => void
  ) {
    const view = this.resolveView(config);
    this.displayError(
      'Something wrong! Please retry or refresh page.',
      view,
      retryCallback
    );
  }

  private handleUnknownError(
    error: any,
    config: MessageConfig,
    retryCallback?: () => void
  ) {
    const view = this.resolveView(config);
    this.displayError(
      'Something wrong! Please retry or refresh page.',
      view,
      retryCallback
    );
  }

  private resolveView(config: MessageConfig) {
    return config.view ? config.view : this.globalMessageView;
  }

  private displayError(
    message: string,
    view: MessageView,
    retryCallback?: () => void
  ) {
    view.showFailure(message, retryCallback);
  }
}
