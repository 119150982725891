import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconSpinnerComponent } from './icon-spinner.component';

@NgModule({
  declarations: [IconSpinnerComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [IconSpinnerComponent],
})
export class IconSpinnerModule {
  constructor() {}
}
