import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './admin/admin.guard';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'about',
    loadChildren: () =>
      import('./about-me/about-me.module').then((m) => m.AboutMeModule),
  },
  {
    path: 'about-me',
    redirectTo: '/about',
    pathMatch: 'full',
  },
  {
    path: 'support',
    redirectTo: '/about',
  },
  {
    path: 'admin',
    canLoad: [AdminGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'fuji-x',
    loadChildren: () =>
      import('./fuji-x/fuji-x.module').then((m) => m.FujiXModule),
  },
  {
    path: 'fujifilm',
    redirectTo: 'fuji-x',
  },
  {
    path: 'photo-galleries',
    loadChildren: () =>
      import('./photo-gallery/photo-gallery.module').then(
        (m) => m.PhotoGalleryModule
      ),
  },
  {
    path: 'photo-stories',
    loadChildren: () =>
      import('./photo-story/photo-story.module').then(
        (m) => m.PhotoStoryModule
      ),
  },
  {
    path: 'photo-services',
    loadChildren: () =>
      import('./photo-service/photo-service.module').then(
        (m) => m.PhotoServiceModule
      ),
  },
  {
    path: 'services',
    redirectTo: '/photo-services',
    pathMatch: 'full',
  },
  { path: 'not-found', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
