import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserPhotoComponent } from './user-photo.component';

@NgModule({
  declarations: [UserPhotoComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [UserPhotoComponent],
})
export class UserPhotoModule {
  constructor() {}
}
