import {gql} from 'apollo-angular';

import { ClientFragment } from 'src/app/models/client/client-fragment';
import { ClientScenario } from 'src/app/models/client/client-scenario';
import { Page } from 'src/app/models/page/page';

export class PageBreadcrumbsConst {
  static readonly BASIC_PAGE_FRAG = new ClientFragment({
    type: Page.TYPENAME,
    name: 'BreadcrumbsComponentBasicPage',
    gqlEntry: gql`
      fragment BreadcrumbsComponentBasicPage on Page {
        id
        urn
        breadcrumbTitle
        path
      }
    `,
  });
  static readonly CURRENT_PAGE_FRAG = new ClientFragment({
    type: Page.TYPENAME,
    name: 'BreadcrumbsComponentCurrentPage',
    gqlEntry: gql`
        fragment BreadcrumbsComponentCurrentPage on Page {
          parentPagesUrns
          ...${PageBreadcrumbsConst.BASIC_PAGE_FRAG.name}
          parentPages {
            ...${PageBreadcrumbsConst.BASIC_PAGE_FRAG.name}
          }
        }
        ${PageBreadcrumbsConst.BASIC_PAGE_FRAG.gqlEntry}
      `,
  });
  static readonly SCENARIO = new ClientScenario({
    pageFragment: PageBreadcrumbsConst.BASIC_PAGE_FRAG,
  });
}
