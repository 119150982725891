<div class="container" [class.float]="config.headerFloat">
  <div class="page-width-limit main-panel">
    <div class="logo-panel">
      <a [routerLink]="['/']" class="logo-anchor">
        <img
          class="icon"
          alt="logo"
          src="assets/images/logo/stw-photo-icon-v3-24x24-mini.svg"
        />
        <div class="name">stw.photo</div>
        <sup class="beta">&nbsp;beta</sup>
      </a>
    </div>
    <div class="menu-panel">
      <a class="menu-item desktop-only" routerLink="/">Home</a>
      <a class="menu-item desktop-only" routerLink="/photo-galleries">
        Galleries
      </a>
      <a class="menu-item desktop-only" routerLink="/photo-stories">
        Stories
      </a>
      <a class="menu-item desktop-only" routerLink="/services"> Services </a>
      <a class="menu-item desktop-only" routerLink="/fuji-x">Fuji Gears</a>
      <a *ngIf="me.isAdmin" class="menu-item desktop-only" routerLink="/admin">
        Admin
      </a>
      <div
        class="menu-item mobile-only sidenav-toggle"
        (click)="onToggleSidenav()"
      >
        <fa-icon [icon]="faBars"></fa-icon>
      </div>
      <app-login-dialog-clickable *ngIf="!me.isMember" class="menu-item log-in">
        Log In
      </app-login-dialog-clickable>
      <app-login-dialog-clickable
        *ngIf="me.isMember"
        class="menu-item photo-menu-item"
      >
        <app-user-photo class="member-photo" [user]="me"></app-user-photo>
      </app-login-dialog-clickable>
    </div>
  </div>
</div>
