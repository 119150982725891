import { Pager } from '../pager/pager';
import { Resolvable } from '../resolvable/resolvable';
import { User } from '../user/user';
import { CommentProposal } from './comment-proposal';
import { Capability } from '../capability/capability';
import { Reaction } from '../react/reaction';
import { Reacting } from '../react/reacting';

export enum CommentState {
  Pending = 'Pending',
  Published = 'Published',
  Withdrawn = 'Withdrawn',
}

export interface AddCommentInput {
  authorUrn: string;
  subjectUrn: string;
  parentUrn: string;
  content: string;
  displayName?: string;
  website?: String;
}

export interface EditCommentInput {
  commentUrn: string;
  authorUrn: string;
  content: string;
  displayName?: String;
  website?: String;
}

export interface ProposedCommentResult {
  actionUrn: string;
  comment: Comment;
  proposal?: CommentProposal;
}

export type MaybeComment = Comment | undefined | null;

export class Comment implements Resolvable {
  static readonly TYPENAME = 'Comment';
  static readonly NULL_URN = 'urn:stw:' + Comment.TYPENAME;
  // tslint:disable-next-line: variable-name
  __typename = Comment.TYPENAME;
  id: string;
  // input
  authorUrn: string;
  subjectUrn: string;
  parentUrn: string;
  content: string;
  displayName?: string;
  website?: string;
  // system
  state: CommentState;
  createdAt: Date;
  editedAt: Date;
  updatedAt: Date;
  // extended
  hasParent: boolean;
  urn: string;
  author: User;
  subject: Resolvable;
  parent: Comment;
  childComments: Pager<Comment>;
  capability: Capability;
  reaction: Reaction;
  reacting: Reacting;

  constructor(init?: Partial<Comment>) {
    Object.assign(this, init);
  }

  static idToUrn(id: string): string {
    return 'urn:stw:' + Comment.TYPENAME + ':' + id;
  }
}
