import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDmVV0DxxYB7PY7ka8vOtL6QCrZ6FDKMec',
    authDomain: 'stw-photo-us-central1.firebaseapp.com',
    databaseURL: 'https://stw-photo-us-central1.firebaseio.com',
    projectId: 'stw-photo-us-central1',
    storageBucket: 'stw-photo-us-central1.appspot.com',
    messagingSenderId: '170089017355',
    appId: '1:170089017355:web:817d8dfc31e8c8f2'
  }
};

export const firebaseUiAuthConfig: firebaseui.auth.Config = {
  //signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
    // {
    //   scopes: ['public_profile', 'email', 'user_likes', 'user_friends'],
    //   customParameters: {
    //     auth_type: 'reauthenticate'
    //   },
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    // },
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // {
    //   requireDisplayName: false,
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    // },
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};
