<a class="menu-item" routerLink="/" (click)="onSidenavClose()">Home</a>
<!-- <a class="menu-item" [routerLink]="['/fuji-x/cameras']" (click)="onSidenavClose()">Fuji Cameras</a> -->
<a class="menu-item" routerLink="/photo-galleries" (click)="onSidenavClose()">
  Galleries
</a>
<a class="menu-item" routerLink="/photo-stories" (click)="onSidenavClose()">
  Stories
</a>
<a class="menu-item" routerLink="/services" (click)="onSidenavClose()">
  Services
</a>
<a class="menu-item" routerLink="/fuji-x" (click)="onSidenavClose()">
  Fuji Gears
</a>
<a class="menu-item" routerLink="/about" (click)="onSidenavClose()"> About </a>
<a
  *ngIf="me.isAdmin"
  class="menu-item"
  routerLink="/admin"
  (click)="onSidenavClose()"
>
  Admin
</a>
