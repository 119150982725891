import { $pagerBasicFragment } from '../pager/pager.defs';
import { ClientFragment } from './client-fragment';

export class ClientScenario {
  capabilityFragment?: ClientFragment;
  commentFragment?: ClientFragment;
  commentProposalFragment?: ClientFragment;
  fujiLensFragment?: ClientFragment;
  fujiTeleconverterFragment?: ClientFragment;
  mailFragment?: ClientFragment;
  productFragment?: ClientFragment;
  pagerFragment?: ClientFragment = $pagerBasicFragment;
  pageFragment?: ClientFragment;
  photoFragment?: ClientFragment;
  photoFolderFragment?: ClientFragment;
  photoGalleryFragment?: ClientFragment;

  constructor(init?: Partial<ClientScenario>) {
    Object.assign(this, init);
  }
}
