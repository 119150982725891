import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-icon-spinner',
  templateUrl: './icon-spinner.component.html',
  styleUrls: ['./icon-spinner.component.scss'],
})
export class IconSpinnerComponent implements OnInit {
  faSpinner = faSpinner;
  constructor() {}

  ngOnInit() {}
}
