import { Firmware } from '../firmware/firmware';
import { FujiLensId } from './fuji-lens-id.enum';
import { FirmwareRelease } from '../firmware/firmware-release';
const firmwareMap: { [shortName: string]: Firmware } = {};

firmwareMap[FujiLensId['XF14mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '3.11',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId['XF16mmF1.4']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.01',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId['XF16mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId.XF18mmF2] = new Firmware({
  current: new FirmwareRelease({
    version: '3.13',
    releasedAt: new Date(2018, 7, 16),
    updates: ['Fix of slight defects']
  })
});
firmwareMap[FujiLensId['XF23mmF1.4']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.01',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId.XF23mmF2] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId['XF27mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.11',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId['XF35mmF1.4']] = new Firmware({
  current: new FirmwareRelease({
    version: '3.11',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId.XF35mmF2] = new Firmware({
  current: new FirmwareRelease({
    version: '1.01',
    releasedAt: new Date(2016, 5, 9),
    updates: ['Enhances the accuracy of Manual focus adjustment']
  })
});
firmwareMap[FujiLensId.XF50mmF2] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId['XF56mmF1.2']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId['XF56mmF1.2APD']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId['XF60mmF2.4']] = new Firmware({
  current: new FirmwareRelease({
    version: '3.13',
    releasedAt: new Date(2018, 7, 16),
    updates: ['Fix of slight defects']
  })
});
firmwareMap[FujiLensId['XF80mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.11',
    releasedAt: new Date(2018, 11, 17),
    updates: [
      'Compatibility with cooperative control by in-body and optical image stabilization with firmware ver.2.00 of X-H1 is added.'
    ]
  })
});
firmwareMap[FujiLensId.XF90mmF2] = new Firmware({
  current: new FirmwareRelease({
    version: '1.02',
    releasedAt: new Date(2016, 6, 21),
    updates: ['Resolves the failure of the firmware upgrade.']
  })
});
firmwareMap[FujiLensId.XF200mmF2] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId['XF8-16mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.00'
  })
});
firmwareMap[FujiLensId['XF10-24mmF4']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.12',
    releasedAt: new Date(2017, 9, 5),
    updates: [
      'Fix for wrong focal length display and shaking in peripheral part of images.'
    ]
  })
});
firmwareMap[FujiLensId['XF16-55mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.12',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId['XF18-55mmF2.8-4']] = new Firmware({
  current: new FirmwareRelease({
    version: '3.22',
    releasedAt: new Date(2017, 9, 5),
    updates: [
      'Fix for wrong focal length display and shaking in peripheral part of images.'
    ]
  })
});
firmwareMap[FujiLensId['XF18-135mmF3.5-5.6']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.11',
    releasedAt: new Date(2016, 9, 6),
    updates: [
      'Improvement of tracking function of parallax correction in the OVF'
    ]
  })
});
firmwareMap[FujiLensId['XF50-140mmF2.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.30',
    releasedAt: new Date(2018, 9, 25),
    updates: ['Addition of compatibility with "XF1.4X TC F2 WR"']
  })
});
firmwareMap[FujiLensId['XF55-200mmF3.5-4.8']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.20',
    releasedAt: new Date(2016, 5, 9),
    updates: ['Improvement of OIS function']
  })
});
firmwareMap[FujiLensId['XF100-400mmF4.5-5.6']] = new Firmware({
  current: new FirmwareRelease({
    version: '1.20',
    releasedAt: new Date(2018, 9, 25),
    updates: ['Addition of compatibility with "XF1.4X TC F2 WR"']
  })
});

export const fujiLensFirmwareData = firmwareMap;
