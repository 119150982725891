export class UrnObj {
  constructor(public type: string, public id: string) {}

  static fromNullable(urn: string | null, types?: string[]): UrnObj | null {
    if (!urn) {
      return null;
    }
    return UrnObj._parse(urn, types);
  }

  static from(urn: string, types?: string[]): UrnObj {
    if (!urn) {
      throw new Error('URN should not be null or empty!');
    }
    return UrnObj._parse(urn, types);
  }

  static toId(urn: string, types?: string[]): string {
    const urnObj = UrnObj.from(urn, types);
    return urnObj.id;
  }

  static idToTuple(id: string): string[] {
    if (!id) {
      throw new Error('ID should not be null or empty!');
    }
    if (id.charAt(0) !== '(') {
      return [id];
    }
    if (id.charAt(id.length - 1) !== ')') {
      throw new Error('Cannot find the ending ")" in ID ' + id);
    }
    return id.substring(1, id.length - 1).split('|');
  }

  static parseType(urn: string, types?: string[]) {
    if (!urn.startsWith('urn:stw:')) {
      throw new Error('Invalid URN format: ' + urn);
    }
    const typeEnd = urn.indexOf(':', 8);
    if (typeEnd < 0) {
      throw new Error('Invalid URN format: ' + urn);
    }
    const type = urn.substring(8, typeEnd);
    if (types) {
      this.validateTypes(type, types);
    }
    return type;
  }

  private static _parse(urn: string, types?: string[]): UrnObj {
    const type = UrnObj.parseType(urn, types);
    if (urn.length < 8 + type.length + 2) {
      throw new Error('Invalid URN format: ' + urn);
    }
    const id = urn.substring(8 + type.length + 1);
    const urnObj = new UrnObj(type, id);
    return urnObj;
  }

  private static validateTypes(type: string, types: string[]) {
    if (!types.includes(type)) {
      throw new Error(`Requires URN types ${types} but got type ${type}`);
    }
  }

  requireTypes(types: string[]) {
    UrnObj.validateTypes(this.type, types);
  }
}
