import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';

@Component({
  selector: 'app-login-dialog-clickable',
  templateUrl: './login-dialog-clickable.component.html',
  styleUrls: ['./login-dialog-clickable.component.scss']
})
export class LoginDialogClickableComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {});
  }
}
