import { LensAperture } from 'src/app/models/lens-basic/lens-aperture';
import { LensExterior } from 'src/app/models/lens-basic/lens-exterior';
import { LensFocalLength } from 'src/app/models/lens-basic/lens-focal-length';
import { LensNames } from 'src/app/models/lens-basic/lens-names';
import { LensRelease } from 'src/app/models/lens-basic/lens-release';
import { LensViewAngle } from 'src/app/models/lens-basic/lens-view-angle';
import { LensWeight } from 'src/app/models/lens-basic/lens-weight';
import { Page } from 'src/app/models/page/page';
import { Pricing } from 'src/app/models/pricing/pricing';
import { Shopping } from 'src/app/models/shopping/shopping';
import { FujiLensRelevance } from '../fuji-lens-relevance/fuji-lens-relevance';
import { LensThread } from '../lens-basic/lens-thread';
import { MacroAbility } from '../macro/macro-ability';
import { Product } from '../product/product';
import { Resolvable } from '../resolvable/resolvable';
import { RichContent } from '../rich-content/rich-content';
import { FujiLensCategory } from './fuji-lens-category';
import { FujiLensConfig } from './fuji-lens-config';
import { FujiLensFeature } from './fuji-lens-feature';
import { FujiLensHighlight } from './fuji-lens-highlight';
import { FujiLensSpecialty } from './fuji-lens-specialty';
import { FujiLensThumbs } from './fuji-lens-thumbs';

export class FujiLens implements Product, Resolvable {
  static readonly TYPENAME = 'FujiLens';
  // tslint:disable-next-line: variable-name
  __typename = FujiLens.TYPENAME;
  id!: string;
  urn!: string;

  aperture!: LensAperture;
  category?: FujiLensCategory;
  cssClasses: string[];
  config?: FujiLensConfig;
  exterior?: LensExterior;
  features?: FujiLensFeature[];
  focalLength?: LensFocalLength;
  highlights?: FujiLensHighlight[];
  isPrime!: boolean;
  isZoom!: boolean;
  labels?: string[];
  macroAbility?: MacroAbility;
  names!: LensNames;
  overview?: RichContent;
  pricing?: Pricing;
  productName!: string;
  release?: LensRelease;
  slug!: string;
  specialty?: FujiLensSpecialty;
  thread?: LensThread;
  thumbs?: FujiLensThumbs;
  viewAngle?: LensViewAngle;
  weight?: LensWeight; // g
  detailPage: Page;

  // client only fields
  shopping: Shopping;
  relevances: FujiLensRelevance[];
}
