import { Resolvable } from 'src/app/models/resolvable/resolvable';
import { Reacting } from './reacting';

export class Reaction implements Resolvable {
  static readonly TYPENAME = 'Reaction';
  // tslint:disable-next-line: variable-name
  __typename = Reaction.TYPENAME;
  id!: string;
  subjectUrn!: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  typeToCount!: { [type: string]: number };
  totalCount!: number;
  urn!: string;
  reacting: Reacting;

  static idFrom(subjectUrn: string) {
    return '(' + subjectUrn + ')';
  }

  static idToUrn(id: string): string {
    return 'urn:stw:' + Reaction.TYPENAME + ':' + id;
  }

  static fromRaw(raw: object): Reaction {
    return raw as Reaction;
  }

  constructor(init?: Partial<Reaction>) {
    Object.assign(this, init);
  }
}
