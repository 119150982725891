import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Me } from 'src/app/models/user/me';
import { MeService } from 'src/app/models/user/me.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './app-sidenav.component.html',
  styleUrls: ['./app-sidenav.component.scss'],
})
export class AppSidenavComponent implements OnInit {
  me: Me = Me.empty();
  @Output() sidenavClose = new EventEmitter();

  constructor(private meService: MeService) {}

  ngOnInit() {
    this.meService.watchMe().subscribe((me) => (this.me = me));
  }
  onSidenavClose() {
    this.sidenavClose.emit();
  }
}
