import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSpinnerModule } from 'src/app/icon-common/icon-spinner/icon-spinner.module';
import { SpinnerOverlayComponent } from './spinner-overlay.component';

@NgModule({
    declarations: [SpinnerOverlayComponent],
    imports: [CommonModule, IconSpinnerModule],
    exports: [SpinnerOverlayComponent]
})
export class SpinnerOverlayModule {}
