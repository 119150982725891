import {gql} from 'apollo-angular';

import { PageBreadcrumbsConst } from 'src/app/page-common/page-breadcrumbs/page-breadcrumbs.const';
import { ClientFragment } from '../client/client-fragment';
import { ClientScenario } from '../client/client-scenario';
import { Page } from '../page/page';
export class AppFrameConst {
  static readonly PAGE_FRAG = new ClientFragment({
    type: Page.TYPENAME,
    name: 'AppFrameServicePage',
    gqlEntry: gql`
        fragment AppFrameServicePage on Page {
          id
          title
          description
          urn
          metadata {
            title
            titleCompleted
            description
          }
          ...${PageBreadcrumbsConst.CURRENT_PAGE_FRAG.name}
        }
        ${PageBreadcrumbsConst.CURRENT_PAGE_FRAG.gqlEntry}
      `,
  });
  static readonly PAGE_SCENARIO = new ClientScenario({
    pageFragment: AppFrameConst.PAGE_FRAG,
  });
}
