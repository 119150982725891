import { Resolvable } from 'src/app/models/resolvable/resolvable';

export const enum ReactType {
  ThumbsUp = 'ThumbsUp',
  ThumbsDown = 'ThumbsDown',
  None = 'None'
}

export class Reacting implements Resolvable {
  static readonly TYPENAME = 'Reacting';
  // tslint:disable-next-line: variable-name
  __typename = Reacting.TYPENAME;

  id!: string;
  authorUrn!: string;
  subjectUrn!: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  type!: ReactType;
  urn!: string;

  constructor(init?: Partial<Reacting>) {
    Object.assign(this, init);
    if (!this.id) {
      this.id = Reacting.idFrom(this.authorUrn, this.subjectUrn);
    }
  }

  static idFrom(authorUrn: string, subjectUrn: string) {
    return '(' + authorUrn + '|' + subjectUrn + ')';
  }

  static idToUrn(id: string): string {
    return 'urn:stw:' + Reacting.TYPENAME + ':' + id;
  }
}
