import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
    serialize(tree: UrlTree): any {
        let path = super.serialize(tree);
        let pathArr = path.split('?');
        pathArr[0] += '/';
        return pathArr.join('?');
    }
}