import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Page } from '../page/page';
import { filter } from 'rxjs/operators';
import { PageService } from '../page/page.service';
import { AppFrameConst } from './app-frame.const';
import { MessageConfig, MessageService } from '../message/message.service';
import { NGXLogger } from 'ngx-logger';

export class AppFrameConfig {
  static readonly DEFAULT = new AppFrameConfig();
  headerFloat: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class AppFrameService {
  /**
   * Null means leaving the page.
   */
  pageInitSubject = new BehaviorSubject<Page | null>(null);
  routerSubscription: Subscription;
  configSubject = new BehaviorSubject<AppFrameConfig>(new AppFrameConfig());

  constructor(
    private router: Router,
    private pageService: PageService,
    private messageService: MessageService,
    private logger: NGXLogger
  ) {
    if (!this.routerSubscription) {
      this.routerSubscription = router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          this.pageInitSubject.next(null);
        });
    }
  }

  notifyPageInitById(pageId: string) {
    this.pageService.getOnePage(AppFrameConst.PAGE_SCENARIO, pageId).subscribe(
      (page) => this.notifyPageInit(page),
      (err) =>
        this.messageService.handleError(
          err,
          new MessageConfig({ level: 'Error', scope: 'Page' }),
          () => this.notifyPageInitById(pageId)
        )
    );
  }

  notifyPageInit(page: Page) {
    this.pageInitSubject.next(page);
    if (!page) {
      this.logger.warn(
        'Page is null during page init. Please check page id in previous calls!'
      );
      return;
    }
    document.title = this.buildTitle(page);
    const description = this.buildDescription(page);
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description);
  }

  private buildTitle(page: Page) {
    const part1 =
      page.metadata && page.metadata.title ? page.metadata.title : page.title;
    const part2 =
      page.metadata && page.metadata.titleCompleted ? '' : ' | stw.photo';
    return part1 + part2;
  }

  private buildDescription(page: Page) {
    const part1 =
      page.metadata && page.metadata.description
        ? page.metadata.description
        : page.description;
    return part1 ? part1 : '';
  }
}
