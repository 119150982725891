import { FirmwareRelease } from './firmware-release';

export class Firmware {
  current: FirmwareRelease;
  historyList: FirmwareRelease;

  constructor(init?: Partial<Firmware>) {
    Object.assign(this, init);
  }
}
