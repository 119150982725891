import {gql} from 'apollo-angular';

import { ClientFragment } from '../client/client-fragment';
import { Pager } from './pager';

export const $pagerFragments = {
  pagerBasicFields: `
    fragment pagerBasicFields on Pager {
      hasMore
      clientCursor
      count
      limit
    }
  `
};

export const $pagerBasicFragment = new ClientFragment({
  name: 'pagerBasicFields',
  type: Pager.TYPENAME,
  gqlEntry: gql`
    fragment pagerBasicFields on Pager {
      hasMore
      clientCursor
      count
      limit
    }
  `
});
