import { Gtag } from 'angular-gtag';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { MeService } from 'src/app/models/user/me.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  constructor(private gtag: Gtag, router: Router, meService: MeService) {
    this.gtagConfig({
      custom_map: { dimension1: 'user_is_admin' }
    });
    router.events
      .pipe(
        // TODO P4 may be extra event for entry page
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        meService.queryMe().subscribe(me => {
          if (me && me.isAdmin) {
            gtag.pageview({ user_is_admin: true });
          } else {
            gtag.pageview();
          }
        });
      });
  }

  private gtagConfig(params: any) {
    params.page_path = null;
    params.page_title = null;
    params.page_location = null;
    this.gtag.pageview(params);
  }
}
