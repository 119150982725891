<div class="container">
  <div aria-label="close" class="close-button" (click)="closeDialog()">X</div>
  <div *ngIf="!me.isMember" class="login-panel">
    <div class="header">Log in with:</div>
    <firebase-ui></firebase-ui>
    <!-- TODO P3 need check style change -->
    <!--app-icon-spinner *ngIf="me.clientState === 'MEMBER_LOGGING_IN'" class="loading"></app-icon-spinner-->
  </div>
  <div *ngIf="me.isMember" class="member-panel">
    <div class="member-photo-row">
      <app-user-photo class="member-photo" [user]="me"></app-user-photo>
    </div>
    <div class="member-name-row">{{ me.displayName }}</div>
    <div class="logout-row">
      <span class="anchor logout" (click)="logout()"> Logout </span>
    </div>
    <div class="actions-row"></div>
    <div *ngIf="me.isAdmin" class="admin-actions">
      <a routerLink="/admin">Admin</a>
    </div>
  </div>
</div>
