import { Resolvable } from '../resolvable/resolvable';
import { PageMetadata } from './page-metadata';

export type MaybePage = Page | undefined | null;

export class Page implements Resolvable {
  static readonly TYPENAME = 'Page';
  // tslint:disable-next-line: variable-name
  __typename = Page.TYPENAME;
  id: string;
  urn!: string;
  title: string;
  description?: string;
  breadcrumbTitle: string;
  linkTitle?: string;
  linkDescription?: string;
  path: string;
  updatedAt?: Date;
  parentPagesUrns?: string[];
  metadata?: PageMetadata;
  parentPages?: Page[];

  constructor(init?: Partial<Page>) {
    Object.assign(this, init);
  }

  static idFrom(pageKey: string, itemUrns?: string[]) {
    if (!itemUrns || itemUrns.length === 0) {
      return pageKey;
    }
    let str = '(';
    str += pageKey;
    if (itemUrns) {
      itemUrns.forEach((itemUrn) => (str += '|' + itemUrn));
    }
    str += ')';
    return str;
  }

  static idToUrn(id: string) {
    return `urn:stw:${Page.TYPENAME}:${id}`;
  }
}
