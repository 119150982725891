import {Apollo, gql} from 'apollo-angular';
import { Injectable } from '@angular/core';


import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrnObj } from 'src/app/models/urn/urn-obj';
import { ClientFragment } from '../client/client-fragment';
import { ClientScenario } from '../client/client-scenario';
import { Firmware } from '../firmware/firmware';
import { FujiLens } from './fuji-lens';
import { fujiLensFirmwareData } from './fuji-lens-firmware.data';
import { FujiLensId } from './fuji-lens-id.enum';
import { FujiLensStats } from './fuji-lens-stats';

const STATS_SCENARIO = new ClientScenario({
  fujiLensFragment: new ClientFragment({
    name: 'StatsScenarioFujiLens',
    type: FujiLens.TYPENAME,
    gqlEntry: gql`
      fragment StatsScenarioFujiLens on FujiLens {
        id
        exterior {
          diameter
          length
          teleLength
          volume
        }
        names {
          full
          short
        }
        weight {
          plain
        }
      }
    `,
  }),
});

@Injectable({
  providedIn: 'root',
})
export class FujiLensService {
  constructor(private apollo: Apollo, private logger: NGXLogger) {
    // TODO P2 shopping
    // $fujiLenses.forEach(lens => {
    //   lens.shopping = shoppingService.createSearchShoppings(
    //     lens.names.searchable == null ? lens.names.short : lens.names.searchable
    //   );
    // });
  }

  getAllLenses(scenario: ClientScenario): Observable<FujiLens[]> {
    this.logger.debug('Loading all lenses in FujiLensService.');
    return this.apollo
      .query<any>({
        query: gql`
          query allFujiLenses {
            allFujiLenses {
              ...${scenario.pagerFragment.name}
              items {
                ... on FujiLens {
                  id
                  ...${scenario.fujiLensFragment.name}
                }
              }
            }
          }
          ${scenario.fujiLensFragment.gqlEntry}
          ${scenario.fujiLensFragment.gqlEntry}
          ${scenario.pagerFragment.gqlEntry}
        `,
      })
      .pipe(map((result) => result.data.allFujiLenses.items));
  }

  getStats(
    scenario: ClientScenario,
    shortName: string | FujiLensId
  ): Observable<FujiLensStats | null> {
    // TODO P2 move to server
    return this.getAllLenses(STATS_SCENARIO).pipe(
      map((fujiLenses) => {
        const targetLens = fujiLenses.find(
          (lens) => lens.names.short === shortName
        );
        if (!targetLens) {
          return null;
        }
        const stats = {} as FujiLensStats;
        if (targetLens.weight && targetLens.weight.plain) {
          stats.weightTopLightest =
            fujiLenses.filter(
              (lens) =>
                lens.weight && targetLens.weight.plain > lens.weight.plain
            ).length + 1;
          stats.weightLighterThan = fujiLenses.filter(
            (lens) => lens.weight && targetLens.weight.plain < lens.weight.plain
          ).length;
        }
        if (targetLens.exterior) {
          stats.sizeTopSmallest =
            fujiLenses.filter(
              (lens) =>
                lens.exterior &&
                targetLens.exterior.volume > lens.exterior.volume
            ).length + 1;
          stats.sizeSmallerThan = fujiLenses.filter(
            (lens) =>
              lens.exterior && targetLens.exterior.volume < lens.exterior.volume
          ).length;
        }
        stats.totalLensesCount = fujiLenses.length;

        return stats;
      })
    );
  }

  findByName(
    scenario: ClientScenario,
    name: string | FujiLensId
  ): Observable<FujiLens | null> {
    if (!name) {
      throw Error('Must specify name!');
    }
    this.logger.debug('Finding lens by name ' + name + '  in FujiLensService.');
    return this.apollo
      .query<any>({
        query: gql`
          query fujiLensByName($name: String!) {
            fujiLensByName(name: $name) {
              ... on FujiLens {
                id
                ...${scenario.fujiLensFragment.name}              }
            }
          }
          ${scenario.fujiLensFragment.gqlEntry}
        `,
        variables: { name },
      })
      .pipe(map((result) => result.data.fujiLensByName));
  }

  findBySlug(
    scenario: ClientScenario,
    slug: string
  ): Observable<FujiLens | null> {
    this.logger.debug('Finding lens by slug ' + slug + '  in FujiLensService.');
    return this.apollo
      .query<any>({
        query: gql`
          query fujiLensBySlug($slug: String!) {
            fujiLensBySlug(slug: $slug) {
              ... on FujiLens {
                id
                ...${scenario.fujiLensFragment.name}              }
            }
          }
          ${scenario.fujiLensFragment.gqlEntry}
        `,
        variables: { slug },
      })
      .pipe(map((result) => result.data.fujiLensBySlug));
  }

  findByUrn(
    scenario: ClientScenario,
    lensUrn: string
  ): Observable<FujiLens | null> {
    const id = UrnObj.toId(lensUrn, [FujiLens.TYPENAME]);
    return this.getLens(scenario, id);
  }

  getLens(
    scenario: ClientScenario,
    lensId: string
  ): Observable<FujiLens | null> {
    this.logger.debug('Loading lens by id ' + lensId + '  in FujiLensService.');
    return this.apollo
      .query<any>({
        query: gql`
          query fujiLens($id: ID!) {
            fujiLens(id: $id) {
              ... on FujiLens {
                id
                ...${scenario.fujiLensFragment.name}
              }
            }
          }
          ${scenario.fujiLensFragment.gqlEntry}
        `,
        variables: { id: lensId },
      })
      .pipe(map((result) => result.data.fujiLens));
  }

  getSomeLenses(
    scenario: ClientScenario,
    lensIds: string[]
  ): Observable<(FujiLens | null)[]> {
    this.logger.debug(`Loading ${lensIds.length} lenses in FujiLensService.`);
    return this.apollo
      .query<any>({
        query: gql`
          query someFujiLenses($ids: [ID!]!) {
            someFujiLenses(ids: $ids) {
              ... on FujiLens {
                id
                ...${scenario.fujiLensFragment.name}              }
            }
          }
          ${scenario.fujiLensFragment.gqlEntry}
        `,
        variables: { ids: lensIds },
      })
      .pipe(map((result) => result.data.someFujiLenses as Array<any>));
  }

  getFirmware(shortName: string | FujiLensId): Observable<Firmware> {
    return of('dummy').pipe(
      map((_) => {
        const firmware = fujiLensFirmwareData[shortName];
        return firmware;
      })
    );
  }
}
