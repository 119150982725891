import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FirebaseuiAngularLibraryService } from 'firebaseui-angular';
import {
  AppFrameConfig,
  AppFrameService,
} from 'src/app/models/app-frame/app-frame.service';
import { Me } from 'src/app/models/user/me';
import { MeService } from 'src/app/models/user/me.service';
import { LoginDialogClickableComponent } from 'src/app/user-common/login-dialog-clickable/login-dialog-clickable.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, AfterViewInit {
  faBars = faBars;
  config: AppFrameConfig = new AppFrameConfig();
  me: Me = Me.empty();
  @Output()
  sidenavToggle = new EventEmitter();
  @ViewChild(LoginDialogClickableComponent)
  private loginClickableComponent: LoginDialogClickableComponent;

  constructor(
    private meService: MeService,
    private router: Router,
    private appFrameService: AppFrameService,
    private libraryService: FirebaseuiAngularLibraryService
  ) {}

  ngOnInit() {
    this.meService.watchMe().subscribe((me) => (this.me = me));
    this.appFrameService.configSubject.subscribe(
      (config) => (this.config = config)
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.libraryService.firebaseUiInstance.isPendingRedirect()) {
        this.loginClickableComponent.onClick();
      }
    }, 100);
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }
}
