import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import { sha256 } from 'crypto-hash';
import { Comment } from './models/comment/comment';
import { FujiLens } from './models/fuji-lens/fuji-lens';
import {
  $fujiLensIdSet,
  FujiLensId,
} from './models/fuji-lens/fuji-lens-id.enum';
import { FujiTeleconverter } from './models/fuji-teleconverter/fuji-teleconverter';
import { Page } from './models/page/page';
import { Reacting } from './models/react/reacting';
import { Reaction } from './models/react/reaction';
import { User } from './models/user/user';

const uri = '/app/api/graphql';
export function createApollo(httpLink: HttpBatchLink) {
  return {
    link: createPersistedQueryLink({ sha256 }).concat(httpLink.create({ uri })),
    cache: new InMemoryCache({
      possibleTypes: {
        Product: ['FujiLens', 'FujiTeleconverter'],
        Resolvable: ['Product'],
      },
      typePolicies: {
        FujiLens: {
          fields: {
            aperture: {
              merge: false,
            },
            category: {
              merge: false,
            },
            config: {
              merge: false,
            },
            exterior: {
              merge: false,
            },
            focalLength: {
              merge: false,
            },
            macroAbility: {
              merge: false,
            },
            names: {
              merge: false,
            },
            overview: {
              merge: false,
            },
            specialty: {
              merge: false,
            },
            thread: {
              merge: false,
            },
            thumbs: {
              merge: false,
            },
            viewAngle: {
              merge: false,
            },
            weight: {
              merge: false,
            },
          },
        },
        Query: {
          // TODO P4 fix cache redirect for arrays
          fields: {
            comment(_, { args, toReference }) {
              return toReference({
                __typename: Comment.TYPENAME,
                id: args.id,
              });
            },
            fujiLens(_, { args, toReference }) {
              return toReference({
                __typename: FujiLens.TYPENAME,
                id: args.id,
              });
            },
            fujiLensByName(_, { args, toReference }) {
              if ($fujiLensIdSet.has(args.name)) {
                return toReference({
                  __typename: FujiLens.TYPENAME,
                  id: args.name,
                });
              } else {
                return undefined;
              }
            },
            // allFujiLenses(_, { args, toReference }) {
            //   return Object.values(FujiLensId).map((id) =>
            //     toReference({
            //       __typename: FujiLens.TYPENAME,
            //       id: id,
            //     })
            //   );
            // },
            fujiTeleconverter(_, { args, toReference }) {
              return toReference({
                __typename: FujiTeleconverter.TYPENAME,
                id: args.id,
              });
            },
            someFujiTeleconverters(_, { args, toReference }) {
              return (args.ids as Array<string>).map((id) =>
                toReference({
                  __typename: FujiTeleconverter.TYPENAME,
                  id: id,
                })
              );
            },
            page(_, { args, toReference }) {
              return toReference({
                __typename: Page.TYPENAME,
                id: args.id,
              });
            },
            reaction(_, { args, toReference }) {
              return toReference({
                __typename: Reaction.TYPENAME,
                id: args.id,
              });
            },
            reacting(_, { args, toReference }) {
              return toReference({
                __typename: Reacting.TYPENAME,
                id: args.id,
              });
            },
            user(_, { args, toReference }) {
              return toReference({
                __typename: User.TYPENAME,
                id: args.id,
              });
            },
          },
        },
        // cacheRedirects: {
        //   Query: {
        //     fujiLensByName: (_, args, { getCacheKey }) => {
        //       if ($fujiLensIdSet.has(args.name)) {
        //         return getCacheKey({
        //           __typename: FujiLens.TYPENAME,
        //           id: args.name,
        //         });
        //       } else {
        //         return undefined;
        //       }
        //     },
        //     allFujiLenses: (_, args, { getCacheKey }) =>
        //       Object.values(FujiLensId).map((id) =>
        //         getCacheKey({ __typename: FujiLens.TYPENAME, id })
        //       ),
        //     fujiTeleconverter: (_, args, { getCacheKey }) =>
        //       getCacheKey({
        //         __typename: FujiTeleconverter.TYPENAME,
        //         id: args.id,
        //       }),
        //     someFujiTeleconverters: (_, args, { getCacheKey }) =>
        //       (args.ids as Array<string>).map((id) =>
        //         getCacheKey({
        //           __typename: FujiTeleconverter.TYPENAME,
        //           id,
        //         })
        //       ),
        //     somePages: (_, args, { getCacheKey }) =>
        //       (args.ids as Array<string>).map((id) =>
        //         getCacheKey({
        //           __typename: Page.TYPENAME,
        //           id,
        //         })
        //       ),
        //     someReactingsByAuthor: (
        //       _,
        //       { authorUrn, subjectsUrns },
        //       { getCacheKey }
        //     ) =>
        //       (subjectsUrns as string[]).map((subjectUrn) =>
        //         getCacheKey({
        //           __typename: Reacting.TYPENAME,
        //           id: Reacting.idFrom(authorUrn, subjectUrn),
        //         })
        //       ),
        //     someProducts: (_, args, { getCacheKey }) =>
        //       (args.productsUrns as Array<string>).map((productUrn) => {
        //         const urnObj = UrnObj.from(productUrn);
        //         return getCacheKey({ __typename: urnObj.type, id: urnObj.id });
        //       }),
        // },
      },
    }),
  };
}

@NgModule({
  imports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
