import { Raw } from "../raw/raw";

export class Pager<TItem> {
  static readonly TYPENAME = 'Pager';
  limit?: number;
  rawItems?: any[];
  items?: TItem[];
  count?: number;
  hasMore?: boolean;
  clientCursor?: string;

  constructor(init?: Partial<Pager<TItem>>) {
    Object.assign(this, init);
  }

  static fromRaw<TItem>(
    rawPager: Raw,
    rawItemToItem: (rawItem: any) => TItem
  ): Pager<TItem> {
    const items = rawPager.items
      ? (rawPager.items as Raw[]).map(rawItem => rawItemToItem(rawItem))
      : undefined;
    return new Pager<TItem>({
      limit: rawPager.limit,
      rawItems: rawPager.items,
      items,
      count: rawPager.count,
      hasMore: rawPager.hasMore,
      clientCursor: rawPager.clientCursor
    });
  }

  static makeEmpty<TItem>(hasMore: boolean = false): Pager<TItem> {
    return new Pager<TItem>({
      limit: 1,
      items: [],
      count: 0,
      hasMore
    });
  }
}
