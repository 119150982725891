import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import firebase from 'firebase';

import Cookies from 'js-cookie';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { Me } from './me';

class GuestLoginInput {
  userId: string;
  guestPassword: string;
}

class MemberLoginInput {
  constructor(init?: Partial<MemberLoginInput>) {
    Object.assign(this, init);
  }
  idToken: string;
  displayName?: string;
  photoUrl?: string;
  email?: string;
  emailVerified?: boolean;
  providerId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private firebaseLoggingIn: boolean;

  private readonly meFrag = gql`
    fragment MeServiceMe on Me {
      id
      userUrn
      isMember
      isAdmin
      mergedFromId
      mergedFromUrn
      displayName
      photoUrl
    }
  `;
  private readonly meQuery = gql`
    query me {
      me {
        ...MeServiceMe
      }
    }
    ${this.meFrag}
  `;
  isAdmin = false;
  private lastMe: Me;

  constructor(
    private apollo: Apollo,
    private cookieService: CookieService,
    private angularFireAuth: AngularFireAuth,
    private logger: NGXLogger
  ) {
    this.watchMe().subscribe((me) => {
      this.isAdmin = false;
      if (!me) {
        this.logger.info('Me becomes null.');
      } else if (me.isGuest) {
        this.logger.info('Me becomes guest.');
      } else if (me.isMember && !me.isAdmin) {
        this.logger.info('Me becomes member.');
      } else if (me.isAdmin) {
        this.isAdmin = true;
        this.logger.info('Me becomes admin.');
      } else {
        this.logger.info('Me is not prepared.');
      }
      this.handleMeChange(this.lastMe, me);
      this.lastMe = me;
    });

    this.angularFireAuth.authState.subscribe((baseUser) => {
      if (baseUser) {
        this.logger.debug(`Received firebase authState with firebase user.`);
        this.onFirebaseLogin(baseUser);
      } else {
        this.logger.debug(`Received firebase authState with null user.`);
        this.onFirebaseLogout();
      }
    });
    this.angularFireAuth.getRedirectResult();
  }

  private onFirebaseLogin(baseUser: firebase.User) {
    this.queryMe().subscribe((currentMe) => {
      if (currentMe && (currentMe.isMember || this.firebaseLoggingIn)) {
        this.logger.info(
          `Skipped firebase login because the current Me is already a member or is logging in.`
        );
        return;
      }
      this.firebaseLoggingIn = true;
      this.loginMemberByFirebase(baseUser).subscribe((me) => {
        this.firebaseLoggingIn = false;
      });
    });
  }

  private onFirebaseLogout() {
    this.queryMe().subscribe((currentMe) => {
      if (currentMe && !currentMe.isMember) {
        this.logger.info(
          `Skipped firebase logout because the current Me is not a member.`
        );
        return;
      }
      this.logoutNow();
    });
  }

  watchMe(): Observable<Me | null> {
    return this.apollo
      .watchQuery<any>({
        query: this.meQuery,
      })
      .valueChanges.pipe(
        map((result) => {
          if (result.data && result.data.me) {
            return Me.fromRaw(result.data.me);
          } else {
            return null;
          }
        })
      );
  }

  queryMe(): Observable<Me | null> {
    const hasSession = this.cookieService.check('connect.sid');
    return this.apollo
      .query<any>({
        query: this.meQuery,
        fetchPolicy: hasSession ? 'cache-first' : 'network-only',
      })
      .pipe(
        map((result) => {
          if (result.data && result.data.me) {
            return Me.fromRaw(result.data.me);
          } else {
            return null;
          }
        })
      );
  }

  /**
   * Makes me logged in if the user can login. If the user cannot login, it won't register a new guest.
   */
  tryPrepare(): Observable<Me | null> {
    return this.queryMe().pipe(
      mergeMap((currentMe) => {
        if (currentMe.isPrepared) {
          return of(currentMe);
        }
        return this.loginMember().pipe(
          mergeMap((me) => (me ? of(me) : this.loginGuest()))
        );
      })
    );
  }

  /**
   * Makes the user logged in. If the user cannot login, it will register the user as a guest.
   */
  prepare(): Observable<Me> {
    return this.queryMe().pipe(
      mergeMap((currentMe) => {
        if (currentMe.isPrepared) {
          return of(currentMe);
        }
        return this.loginMember().pipe(
          mergeMap((me) => (me ? of(me) : this.loginGuest())),
          mergeMap((me) => (me ? of(me) : this.registerGuest()))
        );
      })
    );
  }

  private loginMember(): Observable<Me | null> {
    return from(this.angularFireAuth.currentUser).pipe(
      mergeMap((firebaseUser) =>
        firebaseUser ? this.loginMemberByFirebase(firebaseUser) : of(null)
      )
    );
  }

  private loginMemberByFirebase(baseUser: firebase.User): Observable<Me> {
    return from(baseUser.getIdToken()).pipe(
      mergeMap((idToken) => {
        const input = new MemberLoginInput({
          idToken,
          displayName: baseUser.displayName,
          photoUrl: baseUser.photoURL,
          email: baseUser.email,
          emailVerified: baseUser.emailVerified,
          providerId: baseUser.providerId,
        });
        return this.apollo
          .mutate<any>({
            mutation: gql`
              mutation memberLogin($input: MemberLoginInput!) {
                memberLogin(input: $input) {
                  ...MeServiceMe
                }
              }
              ${this.meFrag}
            `,
            variables: {
              input,
            },
            update: (store, result) => {
              store.writeQuery({
                query: this.meQuery,
                data: { me: result.data.memberLogin },
              });
            },
          })
          .pipe(map((result) => Me.fromRaw(result.data.memberLogin)));
      })
    );
  }

  private loginGuest(): Observable<Me | null> {
    const input = this.loadGuestLoginInput();
    if (!input) {
      return of(null);
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation guestLogin($input: GuestLoginInput!) {
            guestLogin(input: $input) {
              ...MeServiceMe
            }
          }
          ${this.meFrag}
        `,
        variables: {
          input,
        },
        update: (store, result) => {
          store.writeQuery({
            query: this.meQuery,
            data: { me: result.data.guestLogin },
          });
        },
      })
      .pipe(
        map(({ data }) => Me.fromRaw(data.guestLogin)),
        catchError((err) => {
          if (
            err.graphQLErrors &&
            err.graphQLErrors[0] &&
            err.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
          ) {
            this.logger.warn('Got BAD_USER_INPUT when logging in guest!');
            this.clearGuestLoginInput();
            return of(null);
          } else {
            return throwError(err);
          }
        })
      );
  }

  private registerGuest(): Observable<Me | null> {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation guestRegister {
            guestRegister {
              ...MeServiceMe
              credential {
                guestPassword
              }
            }
          }
          ${this.meFrag}
        `,
        update: (store, result) => {
          store.writeQuery({
            query: this.meQuery,
            data: { me: result.data.guestRegister },
          });
        },
      })
      .pipe(
        map((result) => {
          this.logger.info(`Registered guest`);
          const me = Me.fromRaw(result.data.guestRegister);
          return me;
        })
      );
  }

  logoutNow(): void {
    this.logout().subscribe((__) => {});
  }

  logout(): Observable<unknown> {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation meLogout {
            meLogout {
              ...MeServiceMe
            }
          }
          ${this.meFrag}
        `,
        update: (store, result) => {
          store.writeQuery({
            query: this.meQuery,
            data: { me: result.data.meLogout },
          });
        },
      })
      .pipe(
        map((result) => result.data.meLogout),
        mergeMap((_) => from(this.angularFireAuth.signOut()))
      );
  }

  private loadGuestLoginInput(): GuestLoginInput | null {
    const input = new GuestLoginInput();
    if (typeof Storage !== 'undefined' && window && window.localStorage) {
      input.userId = window.localStorage.getItem(this.userIdStorageKey());
      input.guestPassword = window.localStorage.getItem(
        this.guestPasswordStorageKey()
      );
    } else {
      // No Web Storage support...
      input.userId = Cookies.get(this.userIdStorageKey());
      input.guestPassword = Cookies.get(this.guestPasswordStorageKey());
    }

    if (input.userId && input.guestPassword) {
      return input;
    } else {
      return null;
    }
  }

  private userIdStorageKey() {
    return 'stw-userId';
  }

  private guestPasswordStorageKey() {
    return 'stw-guestPassword';
  }

  private saveGuestLoginInput(loginInput: GuestLoginInput) {
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem(this.userIdStorageKey(), loginInput.userId);
      window.localStorage.setItem(
        this.guestPasswordStorageKey(),
        loginInput.guestPassword
      );
    } else {
      this.cookieService.set(this.userIdStorageKey(), loginInput.userId, 3650);
      this.cookieService.set(
        this.guestPasswordStorageKey(),
        loginInput.guestPassword,
        3650
      );
    }
  }

  private clearGuestLoginInput() {
    if (typeof Storage !== 'undefined') {
      window.localStorage.removeItem(this.userIdStorageKey());
      window.localStorage.removeItem(this.guestPasswordStorageKey());
    } else {
      // No Web Storage support...
      Cookies.remove(this.userIdStorageKey());
      Cookies.get(this.guestPasswordStorageKey());
    }
  }

  private handleMeChange(oldMe: Me, newMe: Me): void {
    if (newMe && newMe.credential && newMe.credential.guestPassword) {
      this.saveGuestLoginInput({
        userId: newMe.id,
        guestPassword: newMe.credential.guestPassword,
      });
      newMe.credential = null;
    }
  }

  // TODO P3 ask member to manually merge guest activities
  private memberMergeGuest(input: GuestLoginInput) {
    this.apollo.mutate({
      mutation: gql``,
      variables: { input },
    });
  }
}
