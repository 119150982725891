/**
 * Lens's short name.
 */
export enum FujiLensId {
  'XF14mmF2.8' = 'XF14mmF2.8',
  'XF16mmF1.4' = 'XF16mmF1.4',
  'XF16mmF2.8' = 'XF16mmF2.8',
  'XF18mmF2' = 'XF18mmF2',
  'XF23mmF1.4' = 'XF23mmF1.4',
  'XF23mmF2' = 'XF23mmF2',
  'XF27mmF2.8' = 'XF27mmF2.8',
  'XF35mmF1.4' = 'XF35mmF1.4',
  'XF35mmF2' = 'XF35mmF2',
  'XF50mmF2' = 'XF50mmF2',
  'XF56mmF1.2' = 'XF56mmF1.2',
  'XF56mmF1.2APD' = 'XF56mmF1.2APD',
  'XF60mmF2.4' = 'XF60mmF2.4',
  'XF80mmF2.8' = 'XF80mmF2.8',
  'XF90mmF2' = 'XF90mmF2',
  'XF200mmF2' = 'XF200mmF2',
  'XF8-16mmF2.8' = 'XF8-16mmF2.8',
  'XF10-24mmF4' = 'XF10-24mmF4',
  'XF16-55mmF2.8' = 'XF16-55mmF2.8',
  'XF18-55mmF2.8-4' = 'XF18-55mmF2.8-4',
  'XF18-135mmF3.5-5.6' = 'XF18-135mmF3.5-5.6',
  'XF50-140mmF2.8' = 'XF50-140mmF2.8',
  'XF55-200mmF3.5-4.8' = 'XF55-200mmF3.5-4.8',
  'XF100-400mmF4.5-5.6' = 'XF100-400mmF4.5-5.6'
}

export const $fujiLensIdSet = new Set<FujiLensId>();
Object.values(FujiLensId).forEach(value => $fujiLensIdSet.add(value));
